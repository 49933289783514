<style>
.ProseMirror {
  min-height: 220px;
}
</style>
<template>
  <div>
    <div class="mb-3" v-if="label">
      <h3>{{ label }}</h3>
    </div>
    <tiptap-vuetify
        v-model="textInput"
        :extensions="extensions"
        minHeight="220"
        @init="onTipTapEditorInit"
        @blur="blurFunction"
        @input="inputFunction"
        :disabled="disabled"
    />
    <div class="mx-2 mt-3 mb-2" v-if="errorMessages.length > 0">
      <div class="v-messages error--text theme--light" role="alert">
        <div class="v-messages__wrapper">
          <div class="v-messages__message" v-for="(errorMessage, i) in errorMessages" :key="i">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
  Image,
  Heading
} from 'tiptap-vuetify'
import ImageFileSelector from './TipTapEditor/ImageFileSelector';

export default {
  components: {
    TiptapVuetify
  },
  props: {
    label: {
      type: String,
      required: false,
      default: () => ''
    },
    value: String,
    required: {
      type: Boolean,
      required: false,
      default: () => false
    },
    errorMessages: {
      type: Array,
      required: false,
      default: () => []
    },
    blurFunction: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    inputFunction: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    simple: {
      type: Boolean,
      required: false,
      default: () => false
    },
  },
  data() {
    return {
      tipTapEditor: null,
    }
  },
  computed: {
    textInput: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        if (typeof newValue !== 'undefined' && newValue !== null && !newValue.replace(/<[^>]*>?/gm, '')) {
          // new value is empty, so set it to null.
          newValue = null;
        }
        this.$emit('input', newValue);
      }
    },
    extensions() {
      const extArray = [
        Bold,
        Italic,
        Strike,
        Underline,
        HardBreak,
      ];

      if (this.simple === false) {
        extArray.push(
            [Heading, {
              options: {
                levels: [2, 3]
              }
            }],
            ListItem,
            BulletList,
            OrderedList,
            Link,
            [Image, {
              options: {
                imageSourcesOverride: true,
                imageSources: [{ component: ImageFileSelector, name: 'Bild-Auswahl' }]
              }
            }]);
      }

      return extArray;
    }
  },
  methods: {
    onTipTapEditorInit(editor) {
      this.tipTapEditor = editor;
    },
  },
  watch: {
    value(newValue) {
      this.textInput = newValue;
    }
  },
  created() {
    this.textInput = this.value;
  },
}
</script>
